<template>
    <div class="preloader">
        <img src="assets/img/spinner-white.svg" alt="spinner">
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.preloader {
    align-items: center;
    background: #008757e0;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s linear;
    width: 100%;
    z-index: 9999;
}
</style>