<template>
  <nav class="navbar navbar-expand-lg main-navbar">
    <form class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li>
          <a href="#" data-toggle="sidebar" class="nav-link nav-link-lg"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
      </ul>
    </form>
    <ul class="navbar-nav navbar-right">
      <!-- <li class="dropdown dropdown-list-toggle">
        <a
          href="#"
          data-toggle="dropdown"
          class="nav-link nav-link-lg message-toggle beep"
          ><i class="far fa-envelope"></i
        ></a>
        <div class="dropdown-menu dropdown-list dropdown-menu-right">
          <div class="dropdown-header">
            Messages
            <div class="float-right">
              <a href="#">Mark All As Read</a>
            </div>
          </div>
          <div class="dropdown-list-content dropdown-list-message">
            <a href="#" class="dropdown-item dropdown-item-unread">
              <div class="dropdown-item-avatar">
                <img
                  alt="image"
                  src="assets/img/avatar/avatar-1.png"
                  class="rounded-circle"
                />
                <div class="is-online"></div>
              </div>
              <div class="dropdown-item-desc">
                <b>Kusnaedi</b>
                <p>Hello, Bro!</p>
                <div class="time">10 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item dropdown-item-unread">
              <div class="dropdown-item-avatar">
                <img
                  alt="image"
                  src="assets/img/avatar/avatar-2.png"
                  class="rounded-circle"
                />
              </div>
              <div class="dropdown-item-desc">
                <b>Dedik Sugiharto</b>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
                <div class="time">12 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item dropdown-item-unread">
              <div class="dropdown-item-avatar">
                <img
                  alt="image"
                  src="assets/img/avatar/avatar-3.png"
                  class="rounded-circle"
                />
                <div class="is-online"></div>
              </div>
              <div class="dropdown-item-desc">
                <b>Agung Ardiansyah</b>
                <p>
                  Sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <div class="time">12 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-avatar">
                <img
                  alt="image"
                  src="assets/img/avatar/avatar-4.png"
                  class="rounded-circle"
                />
              </div>
              <div class="dropdown-item-desc">
                <b>Ardian Rahardiansyah</b>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit ess
                </p>
                <div class="time">16 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-avatar">
                <img
                  alt="image"
                  src="assets/img/avatar/avatar-5.png"
                  class="rounded-circle"
                />
              </div>
              <div class="dropdown-item-desc">
                <b>Alfa Zulkarnain</b>
                <p>
                  Exercitation ullamco laboris nisi ut aliquip ex ea commodo
                </p>
                <div class="time">Yesterday</div>
              </div>
            </a>
          </div>
          <div class="dropdown-footer text-center">
            <a href="#">View All <i class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </li> -->
      <!-- <li class="dropdown dropdown-list-toggle">
        <a
          href="#"
          data-toggle="dropdown"
          class="nav-link notification-toggle nav-link-lg beep"
          ><i class="far fa-bell"></i
        ></a>
        <div class="dropdown-menu dropdown-list dropdown-menu-right">
          <div class="dropdown-header">
            Notifications
            <div class="float-right">
              <a href="#">Mark All As Read</a>
            </div>
          </div>
          <div class="dropdown-list-content dropdown-list-icons">
            <a href="#" class="dropdown-item dropdown-item-unread">
              <div class="dropdown-item-icon bg-primary text-white">
                <i class="fas fa-code"></i>
              </div>
              <div class="dropdown-item-desc">
                Template update is available now!
                <div class="time text-primary">2 Min Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-icon bg-info text-white">
                <i class="far fa-user"></i>
              </div>
              <div class="dropdown-item-desc">
                <b>You</b> and <b>Dedik Sugiharto</b> are now friends
                <div class="time">10 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-icon bg-success text-white">
                <i class="fas fa-check"></i>
              </div>
              <div class="dropdown-item-desc">
                <b>Kusnaedi</b> has moved task <b>Fix bug header</b> to
                <b>Done</b>
                <div class="time">12 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-icon bg-danger text-white">
                <i class="fas fa-exclamation-triangle"></i>
              </div>
              <div class="dropdown-item-desc">
                Low disk space. Let's clean it!
                <div class="time">17 Hours Ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item">
              <div class="dropdown-item-icon bg-info text-white">
                <i class="fas fa-bell"></i>
              </div>
              <div class="dropdown-item-desc">
                Welcome to Stisla template!
                <div class="time">Yesterday</div>
              </div>
            </a>
          </div>
          <div class="dropdown-footer text-center">
            <a href="#">View All <i class="fas fa-chevron-right"></i></a>
          </div>
        </div>
      </li> -->
      <li class="dropdown">
        <a
          href="#"
          data-toggle="dropdown"
          class="nav-link dropdown-toggle nav-link-lg nav-link-user"
        >
          <img
            alt="image"
            src="assets/img/avatar/avatar-1.png"
            class="rounded-circle mr-1"
          />
          <div class="d-sm-none d-lg-inline-block">Hi, {{ `${user.name.split(" ")[0]}` }}</div></a
        >
        <div class="dropdown-menu dropdown-menu-right">
          <div class="dropdown-title">Category: {{ userType }}</div>
          <router-link to="/admin/password/update" class="dropdown-item has-icon" v-if="userType != 'agent'">
            <i class="fas fa-cog"></i> Settings
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item has-icon text-danger" @click.prevent="logoutUser()">
            <i class="fas fa-sign-out-alt"></i> Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import AuthHelpers from '../../functions/actions/AuthRequest'
export default {
  name: "MainNavigation",
  computed: {
    ...mapState(['user', 'userType'])
  },
  methods: {
    logoutUser() {
      this.$confirm("Are you sure?").then(async () => {
        const response = await AuthHelpers.logoutRequest()
        if (response.status) {
          this.showAlert('Success', 'User logged out', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      })
    }
  },
};
</script>

<style scoped>
</style>
