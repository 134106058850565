<template>
  <div class="main-sidebar sidebar-style-2">
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand">
        <router-link to="/">
          <img src="assets/img/asolar-logo.png" alt="Logo" width="80%">
        </router-link>
      </div>
      <div class="sidebar-brand sidebar-brand-sm">
        <router-link to="/">
          <img src="assets/img/asolar-logo.png" alt="Logo" width="80%">
        </router-link>
      </div>
      <ul class="sidebar-menu">
        <li class="menu-header">Dashboard</li>
        <li :class="[currentRoute == 'Dashboard' ? 'active' : '']">
          <router-link class="nav-link" to="/"
            ><i class="fas fa-fire"></i> <span>Dashboard</span></router-link
          >
        </li>
        <li class="menu-header" v-if="userType != 'agent'">Modules</li>
        <li class="dropdown" :class="[$route.path.includes('/calls/incoming') ? 'active' : '']"  v-if="userType != 'agent'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="fa fa-phone" /><span>Incoming Call</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'All Incoming Calls' ? 'active' : '']">
              <router-link class="nav-link" to="/calls/incoming">List</router-link>
            </li>
            <li :class="[currentRoute == 'Add Incoming Call' ? 'active' : '']">
              <router-link class="nav-link" to="/calls/incoming/create"
                >Add Incoming Call</router-link
              >
            </li>
          </ul>
        </li>
        <li class="dropdown" :class="[$route.path.includes('/tickets') ? 'active' : '']" v-if="userType != 'agent'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="far fa-file-alt" /><span>Tickets</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'Tickets' ? 'active' : '']">
              <router-link class="nav-link" to="/tickets">All Tickets</router-link>
            </li>
            <li :class="[currentRoute == 'Create Ticket' ? 'active' : '']">
              <router-link class="nav-link" to="/tickets/create"
                >Create Ticket</router-link
              >
            </li>
          </ul>
        </li>
        <li class="dropdown" :class="[$route.path.includes('/tasks') ? 'active' : '']" v-if="userType != 'agent'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="fas fa-bolt" /><span>Tasks</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'Tasks' ? 'active' : '']">
              <router-link class="nav-link" to="/tasks">All Tasks</router-link>
            </li>
            <li :class="[currentRoute == 'Create Task' ? 'active' : '']" v-if="userType == 'admin'">
              <router-link class="nav-link" to="/tasks/create"
                >Create Task</router-link
              >
            </li>
          </ul>
        </li>
        <li class="menu-header" v-if="userType != 'agent'">People Management</li>
        <li class="dropdown" :class="[$route.path.includes('/admin/customers') ? 'active' : '']" v-if="userType != 'agent'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="far fa-address-card" /><span>Customers</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'Customers' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/customers">All Customers</router-link>
            </li>
            <li :class="[currentRoute == 'Create Customer' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/customers/create"
                >Create Customer</router-link
              >
            </li>
          </ul>
        </li>
        <li class="dropdown" :class="[$route.path.includes('/admin/users') ? 'active' : '']" v-if="userType == 'admin'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="far fa-user" /><span>Users</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'Users' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/users">All Users</router-link>
            </li>
            <li :class="[currentRoute == 'Create User' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/users/create"
                >Create User</router-link
              >
            </li>
          </ul>
        </li>
        <li class="menu-header">Settings</li>
        <li class="dropdown" :class="[$route.path.includes('/admin/issues') ? 'active' : '']" v-if="userType == 'admin'">
          <a href="#" class="nav-link has-dropdown" data-toggle="dropdown"
            ><i class="fas fa-exclamation" /><span>Issues</span></a
          >
          <ul class="dropdown-menu">
            <li :class="[currentRoute == 'Issues' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/issues">All Issues</router-link>
            </li>
            <li :class="[currentRoute == 'Create Issues' ? 'active' : '']">
              <router-link class="nav-link" to="/admin/issues/create"
                >Create Issues</router-link
              >
            </li>
          </ul>
        </li>
        <li :class="[currentRoute == 'Change Password' ? 'active' : '']" v-if="userType != 'agent'">
          <router-link class="nav-link" to="/admin/password/update"
            ><i class="fas fa-unlock"></i> <span>Change Password</span></router-link
          >
        </li>
        <li :class="[currentRoute == 'Angaza Report' ? 'active' : '']" v-if="userType != 'employee'">
          <router-link class="nav-link" to="/daily/report"
            ><i class="fas fa-file"></i> <span>Angaza Report</span></router-link
          >
        </li>
      </ul>

      <div class="mt-4 mb-4 p-3 hide-sidebar-mini">
        <a
          href="#"
          class="btn btn-primary btn-lg btn-block btn-icon-split"
           @click.prevent="logoutUser()"
        >
          <i class="fas fa-rocket"></i> Logout
        </a>
      </div>
    </aside>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AuthHelpers from '../../functions/actions/AuthRequest'
export default {
  name: "SideMenu",
  computed: {
    ...mapState(['userType']),
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    logoutUser() {
      this.$confirm("Are you sure?").then(async () => {
        const response = await AuthHelpers.logoutRequest()
        if (response.status) {
          this.showAlert('Success', 'User logged out', 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      })
    }
  },
};
</script>
<style scoped>
</style>