<template>
  <footer class="main-footer">
    <div class="footer-left">
      Copyright &copy; 2018
      <div class="bullet"></div>
      Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
    </div>
    <div class="footer-right"></div>
  </footer>
</template>
<script>
export default {
    name: 'Footer'
};
</script>
<style scoped>
</style>