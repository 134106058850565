<template>
  <div id="app">
    <preloader v-if="loading" />
    <div v-if="!isUserLoggedIn">
      <router-view />
    </div>
    <div class="main-wrapper main-wrapper-1" v-else>
      <div class="navbar-bg"></div>
      <main-navigation />
      <side-menu />
      <!-- Main Content -->
      <div class="main-content">
        <router-view />
      </div>
      <footer />
      <router-link to="/calls/incoming/create" class="float">
        <i class="fa fa-phone my-float"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainNavigation from './components/layouts/MainNavigation'
import SideMenu from './components/layouts/SideMenu'
import Preloader from './components/layouts/Preloader'
import Footer from './components/layouts/Footer'

export default {
  name: "App",
  computed: {
    ...mapState(['loading', 'isUserLoggedIn']),
  },
  components: {
    MainNavigation,
    SideMenu,
    Preloader,
    Footer
  },
  mounted() {},
  data() {
    return {}
  },
  methods: {},
};
</script>

<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
input.form-control::placeholder {
  color: #c5c3c396;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.swal2-popup {
  font-size: 0.7rem !important;
}
.float{
	position:fixed;
	width: 50px;
	height: 50px;
	bottom: 20px;
	right: 20px;
	background-color:#008757;
	color:#FFF;
	border-radius: 50px;
	text-align: center;
	box-shadow: 2px 2px 3px #999;
  z-index: 1;
}
.float:hover {
  color: #FFF;
  background-color: #fece01;
}
.my-float{
	margin-top: 18px;
}
</style>
