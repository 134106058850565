import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    alias: "/login",
    meta: { pageTitle: 'Login' }
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/Auth/AuthForgotPassword.vue'),
    meta: { pageTitle: 'Forgot Password' }
  },
  {
    path: '/auth/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/Auth/AuthResetPassword.vue'),
    meta: { pageTitle: 'Reset Password' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Dashboard' }
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import('../views/Dashboard/Tickets/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Tickets' },
  },
  {
    path: '/tickets/create',
    name: 'Create Ticket',
    component: () => import('../views/Dashboard/Tickets/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Create Ticket' },
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/Dashboard/Tasks/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Tasks' },
  },
  {
    path: '/tasks/create',
    name: 'Create Task',
    component: () => import('../views/Dashboard/Tasks/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Create Task' },
  },
  {
    path: '/tasks/view/:taskId',
    name: 'View Task',
    component: () => import('../views/Dashboard/Tasks/View.vue'),
    props: true,
    meta: { requiresAuth: true, pageTitle: 'View Task' },
  },
  {
    path: '/admin/users',
    name: 'All Users',
    component: () => import('../views/Dashboard/Users/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Users' },
  },
  {
    path: '/admin/users/create',
    name: 'Create User',
    component: () => import('../views/Dashboard/Users/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Create User' },
  },
  {
    path: '/admin/users/edit/:userId',
    name: 'Edit User',
    component: () => import('../views/Dashboard/Users/Edit.vue'),
    meta: { requiresAuth: true, pageTitle: 'Edit User' },
    props: true,
  },
  {
    path: '/admin/customers',
    name: 'All Customers',
    component: () => import('../views/Dashboard/Customers/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Customers' },
  },
  {
    path: '/admin/customers/create',
    name: 'Create Customer',
    component: () => import('../views/Dashboard/Customers/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Create Customer' },
  },
  {
    path: '/admin/issues',
    name: 'All Issues',
    component: () => import('../views/Dashboard/Issues/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Issues' },
  },
  {
    path: '/admin/issues/create',
    name: 'Create Issue',
    component: () => import('../views/Dashboard/Issues/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Create Issue' },
  },
  {
    path: '/admin/password/update',
    name: 'Change Password',
    component: () => import('../views/Dashboard/Password/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Change Password' },
  },
  {
    path: '/calls/incoming',
    name: 'All Incoming Calls',
    component: () => import('../views/Dashboard/Calls/Incoming/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'All Incoming Calls' },
  },
  {
    path: '/calls/incoming/view/:callId',
    name: 'View Incoming Calls',
    component: () => import('../views/Dashboard/Calls/Incoming/View.vue'),
    props: true,
    meta: { requiresAuth: true, pageTitle: 'View Incoming Calls' },
  },
  {
    path: '/calls/incoming/create',
    name: 'Add Incoming Call',
    component: () => import('../views/Dashboard/Calls/Incoming/Create.vue'),
    meta: { requiresAuth: true, pageTitle: 'Add Incoming Call' },
  },
  {
    path: '/daily/report',
    name: 'Angaza Report',
    component: () => import('../views/Dashboard/Report/Daily/Index.vue'),
    meta: { requiresAuth: true, pageTitle: 'Angaza Report' },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  store
})

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("setResponse");
  if (to.matched.some(record => record.meta.requiresAuth) && loggedIn == null) {
    next("/login");
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  document.title = `${process.env.VUE_APP_TITLE} | ${to.meta.requiresAuth ? to.meta.pageTitle : to.name}`
  window.scroll(0, 0)
  NProgress.done();
})

export default router
