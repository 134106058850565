<template>
  <section class="section">
    <div class="container mt-5">
      <div class="row">
        <div
          class="
            col-12 col-sm-8
            offset-sm-2
            col-md-6
            offset-md-3
            col-lg-6
            offset-lg-3
            col-xl-4
            offset-xl-4
          "
        >
          <div class="login-brand">
            <img
              src="assets/img/asolar-logo.png"
              alt="logo"
              width="70%"
              class="shadow-light"
            />
          </div>

          <div class="card card-primary">
            <div class="card-header"><h4>Login</h4></div>
            <div class="card-body">
              <form
                method="POST"
                action="#"
                class="needs-validation"
                novalidate=""
                autocomplete="yes"
              >
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    tabindex="1"
                    v-model="fields.email"
                    required
                    autofocus
                  />
                  <div class="invalid-feedback">Please fill in your email</div>
                </div>
                <div class="form-group">
                  <div class="d-block">
                    <label for="password" class="control-label">Password</label>
                    <!-- <div class="float-right">
                      <router-link to="/auth/forgot-password" class="text-small">
                        Forgot Password?
                      </router-link>
                    </div> -->
                  </div>
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    tabindex="2"
                    v-model="fields.password"
                    required
                  />
                  <div class="invalid-feedback">
                    please fill in your password
                  </div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg btn-block"
                    tabindex="4"
                    :disabled="loading"
                    @click.prevent="loginUser()"
                  >
                    {{ !loading ? 'Login' : 'Loading...Please wait' }}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="simple-footer">{{ loading ? "Loading..." : "Copyright"}} &copy; Asolar Nigeria Limited {{ new Date().getFullYear() }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import AuthHelpers from '../functions/actions/AuthRequest'
export default {
  name: "Login",
  computed: {
    ...mapState(["loading"]),
  },
  beforeMount() {
    if (this.$store.state.isUserLoggedIn) {
      this.$router.push({
        name: 'Dashboard'
      });
    }
  },
  data() {
    return {
      fields: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    async loginUser() {
      if (this.validateRequest()) {
        const response = await AuthHelpers.loginRequest(this.fields)
        if (response.status) {
          // this.showAlert('Success', 'User logged in successful', 'success')
          setTimeout(() => {
            this.$window.location.reload()
          }, 0);
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      }
    },
    validateRequest() {
      if (this.fields.email) {
        if (this.fields.password) {
          return true
        } else {
          this.showAlert('Validation Error', 'Kidnly provide your password', 'error')
        }
      } else {
        this.showAlert('Validation Error', 'Kidnly provide your email address', 'error')
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      })
    }
  },
};
</script>

<style scoped>
</style>